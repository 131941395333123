<script>
  import { toMilliseconds } from "duration-fns";
  import { fetchUpdatePolicy, fetchCreatePolicy } from "./api";
  import { get, range, filter, clone } from "lodash-es";
  import { parse as parsems } from "duration-fns";
  import { query } from "./util/router";
  import { sortBy } from "./util/sort";
  import { format, utcToZonedTime } from "date-fns-tz";
  import { thumbnail } from "./util/filepreview";

  export let policy = null;
  export let issue = true;
  export let disabled = false;
  export let readonly = false;

  let submittable = false;
  let submitting = false;

  // react only if in an unchanged state
  //$: editing = policy && !submittable ? {...policy} : editing;
  let editing = null;

  // save the first one we get
  $: if (!!policy && !editing) editing = { ...policy };
  //$: if(!!policy && (!editing || !submittable)) editing  = {...policy};

  let selectedMinDurationISO = null;

  $: minDurationISO =
    selectedMinDurationISO ||
    get(editing, "duration.min") ||
    get(editing, "duration") ||
    "PT30M";
  $: maxDurationISO =
    get(editing, "duration.max") || get(editing, "duration") || "PT1H";
  $: issue = !!get(editing, "issue.enabled");
  $: disabled = !!get(editing, "disabled");
  $: unitType = get(editing, "tenant.format") || "unit";
  $: minDurationMS = toMilliseconds(minDurationISO);
  $: maxDurationMS = toMilliseconds(maxDurationISO);
  $: maxDurationValues = calculateMaxDuration(minDurationMS);
  $: newPhotoUrl = null;
  $: photoUrl =
    newPhotoUrl ||
    (editing && editing.photo && thumbnail(editing.photo.url, 500));
  //$: console.log("editing=", editing);
  //$: console.log("issue=", issue, "disabled=", disabled);

  function change(e) {
    console.log("change", e);
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    await fetchUpdatePolicy(editing, formData);

    // clear the state
    submitting = submittable = false;
    editing = null;

    if (newPhotoUrl) {
      const oldPhotoUrl = newPhotoUrl;
      newPhotoUrl = null;
      URL.revokeObjectURL(oldPhotoUrl);
    }
  }

  function calculateMaxDuration(min) {
    const max = 24 * 60 * 60 * 1000;
    const items = [];
    console.log("min=", min, "max=", max);
    if (min <= 0) return items;
    let i = min;

    // first 8 steps then on the hour
    for (i = min; i < max; i += min) {
      console.log(i);
      if (i <= 8 * min) items.push(i);
      else if (i % (60 * 60 * 1000) === 0) items.push(i);
    }

    return items;
  }

  function toISOString(ms) {
    const parsed = parsems(ms);
    return (
      "PT" +
      (parsed.hours > 0 ? parsed.hours + "H" : "") +
      (parsed.minutes > 0 ? parsed.minutes + "M" : "")
    );
  }

  function formatTime(ms) {
    const parsed = parsems(ms);
    return `${parsed.hours}:${parsed.minutes.toString().padStart(2, "0")}`;
  }

  function file(e) {
    const input = e.target;
    if (!input.files && !input.files[0]) return;
    const oldPhotoUrl = newPhotoUrl;
    newPhotoUrl = URL.createObjectURL(input.files[0]);
    if (oldPhotoUrl) URL.revokeObjectURL(oldPhotoUrl);
  }

  function issueDisabledChange(e) {
    if (e.target.checked === false) return; // only looking for target

    switch (e.target.value) {
      case "true":
        disabled = !(issue = true);
        break;
      case "false":
        disabled = issue = false;
        break;
      case "":
        disabled = editing.disabled = !(issue = false);
        break;
    }
  }
</script>

{#if editing}
  <form on:change={change} on:input={change} on:submit={submit}>
    <header>
      <h1>Basic</h1>
    </header>
    <fieldset disabled={readonly && "disabled"}>
      <ul>
        <li>
          <figure>
            <img
              class="policy {editing.title
                .toLowerCase()
                .split(/[^a-z]+/gi)
                .join(' ')}"
              alt="amenity"
              src={photoUrl}
            />
          </figure>
        </li>
        <li>
          <input
            id="policy-photo-new"
            type="file"
            title="Select photo"
            accept="image/*"
            name="photo"
            on:change={file}
            disabled={submitting}
          />
          <label for="policy-photo-new">Select photo</label>
        </li>
      </ul>
    </fieldset>

    <fieldset disabled={readonly && "disabled"}>
      <ul>
        <li>
          <label for="policy-title">Name</label>
          <input
            id="policy-title"
            name="title"
            type="text"
            required
            value={get(editing, "title", "")}
          />
        </li>
        <li>
          <label for="policy-enabled">Enabled</label>
          <select
            id="policy-enabled"
            name="enabled"
            value={editing.enabled + ""}
          >
            <option value="true">yes (in account)</option>
            <option value="false">no (Boss only)</option>
          </select>
        </li>
        <!-- <li>
          <label for="policy-parking">Usage</label>
          <select
            id="policy-parking"
            name="parking"
            value={editing.parking + ""}
          >
            <option value="true">parking</option>
            <option value="false">amenity</option>
          </select>
        </li> -->
        <li>
          <label for="policy-parking">Amenity</label>
          <select
            id="policy-amenity"
            name="amenity"
            value={editing.amenity ?? ""}
          >
            <option value="">n/a</option>
            <option value="yes">yes</option>
            <option value="parking">parking</option>
            <option value="bicycle_parking">bike storage</option>
            <option value="self_storage">self storage</option>
            <option value="resident">resident</option>
          </select>
        </li>
        <li>
          <label for="policy-parking">Leisure</label>
          <select
            id="policy-leisure"
            name="leisure"
            value={editing.leisure ?? ""}
          >
            <option value="">n/a</option>
            <option value="swimming_pool">swimming pool</option>
          </select>
        </li>
        <li>
          <label for="policy-priority">Priority</label>
          <select
            id="policy-priorty"
            name="rank"
            value={get(editing, "rank", "") + ""}
          >
            <option value="">default</option>
            {#each [...Array(21).keys()].map((i) => i - 10) as index}
              <option value={index + ""}>{index}</option>
            {/each}
          </select>
        </li>
        <li>
          <label for="policy-public">Navigation</label>
          <select id="policy-public" name="public" value={editing.public + ""}>
            <option value="true">standard listing</option>
            <option value="false">hidden & direct link only</option>
          </select>
        </li>
        <li>
          <label for="policy-audience-admin">By</label>
          <select
            id="policy-audience-admin"
            name="audience.admin"
            value={editing.audience.admin + ""}
          >
            <option value="false">public</option>
            <option value="true">admin only</option>
          </select>
        </li>
        <li>
          <label for="policy-permit-audience">For</label>
          <select
            id="policy-permit-audience"
            name="permit.audience"
            value={editing.permit.audience + ""}
          >
            <option value="any">any</option>
            <option value="visitor">visitor</option>
            <option value="occupant">occupant</option>
            <option value="customer">customer</option>
            <option value="staff">staff</option>
          </select>
        </li>
        <li>
          <label for="policy-permits">Status</label>
          <select
            id="policy-permits"
            name="permit.issue.enabled"
            value={disabled ? "" : issue + ""}
            on:blur={issueDisabledChange}
            on:change={issueDisabledChange}
          >
            <option value="true">permit issued</option>
            <option value="false">info only</option>
            <option value="">closed 24/7</option>
          </select>
        </li>
        {#if issue}
          <li>
            <label for="policy-permit-type">Type</label>
            <select
              id="policy-permit-type"
              name="permit.continuous"
              value={get(editing, "permit.continuous", false) + ""}
            >
              <option value="true">assigned</option>
              <option value="false">temporary</option>
            </select>
          </li>
          <li>
            <label for="policy-permit-format">Output</label>
            <select
              id="policy-permit-format"
              name="permit.printed.required"
              value={get(editing, "permit.printed.required", false) + ""}
            >
              <option value="false">electronic permit</option>
              <option value="true">printed pass</option>
            </select>
          </li>
          <li>
            <label for="policy-permit-action">Action</label>
            <input
              type="text"
              value={get(editing, "issue.title", "")}
              disabled
            />
          </li>
          <li>
            <label for="policy-versions">Versions</label>
            <button
              type="button"
              on:click={(e) => {
                query("version", editing.version.id);
              }}>open</button
            >
            <!-- <select id="policy-versions" on:change={e => {
                    query("version", e.target.value);
                    e.target.selectedIndex = 0;
                }}>
                    {#each sortBy(Object.entries(policy.versions.items), ([ interval, version]) => interval, undefined, true) as [ interval, version ]}
                    <option value="{version}">{ [ interval.split('/')[1] ].map(s => s ? format(utcToZonedTime(s, editing.timezone), "MMM d yyyy h:mm:ss a zzz", { timeZone: editing.timezone }) : "current")}</option>
                    {/each}
                    </select> -->
          </li>
        {/if}
      </ul>
    </fieldset>

    <!-- <fieldset disabled={readonly && "disabled"}>
        <ul class="toggle" on:change={issueDisabledChange}>
            <li><input id="policy-permits-true" type="radio" name="permit.issue.enabled" value="true" checked={!disabled && issue} /><label for="policy-permits-true">Require Pass</label></li>
            <li><input id="policy-permits-false" type="radio" name="permit.issue.enabled" value="false" checked={!disabled && !issue} /><label for="policy-permits-false">Info Only</label></li>
            <li><input id="policy-permits-disabled" type="radio" name="permit.issue.enabled" value="" checked={disabled} /><label for="policy-permits-disabled">Closed 24/7</label></li>
        </ul>
    </fieldset> -->

    {#if disabled}
      <fieldset disabled={readonly && "disabled"} class="closed">
        <p>Closed message:</p>
        <ul>
          <li>
            <!-- <label for="policy-disabled">Message</label> -->
            <textarea
              id="policy-disabled"
              name="disabled.reason"
              value={get(editing, "disabled.reason", "")}
              required
              placeholder="required"
            />
          </li>
        </ul>
      </fieldset>
      <!-- <p>Note: We'll restore your saved settings when you re-open this amenity.</p> -->
    {:else}
      <input type="hidden" name="issue.disabled.title" value="" />
    {/if}

    {#if !disabled}
      <fieldset disabled={readonly && "disabled"}>
        <p>Alert message:</p>
        <ul>
          <li>
            <!-- <label for="policy-alert">Alert</label> -->
            <textarea
              id="policy-alert"
              name="alert.text"
              value={get(editing, "alert.text", "")}
              placeholder="optional"
            />
          </li>
        </ul>
      </fieldset>
      <fieldset disabled={readonly && "disabled"}>
        <p>Info message:</p>
        <ul>
          <li>
            <!-- <label for="policy-info">Info</label> -->
            <textarea
              id="policy-info"
              name="info.text"
              value={get(editing, "info.text", "")}
              placeholder="optional"
            />
          </li>
        </ul>
      </fieldset>
    {/if}

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
{/if}
